import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", {
    class: "fixed right-0 bottom-0 rounded-full bg-gray-lp-800 cursor-pointer mr-4",
    style: {"padding":"10px","margin-bottom":"85px"},
    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.doScroll && _ctx.doScroll(...args)))
  }, [
    _createVNode("img", {
      src: require('@/app/ui/assets/svg/arrow-right-white.svg'),
      alt: "control-action",
      class: "w-6 transition-all",
      style: 
        _ctx.scrollPercent === 100
          ? 'transform: rotate(-90deg);'
          : 'transform: rotate(90deg);'
      
    }, null, 12, ["src"])
  ]))
}