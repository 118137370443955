
import { Vue, Options, prop } from "vue-class-component";
import FloatingAction from "@/app/ui/components/floating-action/index.vue";
import {
  firstCapitalize,
  formatDate,
  scrollHeightPercent
} from "@/app/infrastructures/misc/Utils";
import { PropType } from "vue";
import { SubData } from "@/domain/entities/MainApp";

class Props {
  onBack = prop<Function>({
    type: Function,
    required: false,
    default: Function as PropType<() => void>
  });
  title = prop<string>({
    type: String,
    default: ""
  });
  subData = prop<SubData>({
    type: Object,
    default: new SubData()
  });
  loading = prop<boolean>({
    type: Boolean,
    default: false
  });
  error = prop<boolean>({
    type: Boolean,
    default: false
  });
  errorType = prop<string>({
    type: String,
    default: ""
  });
  onTryAgain = prop<Function>({
    type: Function,
    required: false,
    default: Function as PropType<() => void>
  });
  verticalSeparator = prop<boolean>({
    type: Boolean,
    default: true
  });
  isConfirm = prop<boolean>({
    default: false,
    type: Boolean
  });
  onDelete = prop<Function>({
    default: Function as PropType<() => void>,
    type: Function
  });
  onConfirm = prop<Function>({
    default: Function as PropType<() => void>,
    type: Function
  });
  scrollSidebar = prop<boolean>({
    default: false,
    type: Boolean
  });
  customClass = prop<string>({
    default: "pr-4 overflow-y-auto",
    type: String
  });
  fullPage = prop<boolean>({
    default: false,
    type: Boolean
  });
  displayBack = prop<boolean>({
    default: true,
    type: Boolean
  });
  leavePageConfirmation = prop<boolean>({
    default: false,
    type: Boolean
  });
  noSpaceHeader = prop<boolean>({
    default: false,
    type: Boolean
  });
  isTab = prop<boolean>({
    default: false,
    type: Boolean
  });
  showContent = prop<boolean>({
    default: true,
    type: Boolean
  });
  isFloatingAction = prop<boolean>({
    default: false,
    type: Boolean
  });
  usePaddingVertical = prop<string>({
    default: 'py-6',
    type: String

  })
}

@Options({
  components: {
    FloatingAction
  },
  emits: ["update:leavePageConfirmation", "leave-page"]
})
export default class DetailLayout extends Vue.with(Props) {
  mounted() {
    this.dataRef = this.$refs.detailLayoutData;
  }

  // floating action
  dataRef: any = null;
  scrollPercent = 0;
  onScroll(e: any) {
    this.scrollPercent = scrollHeightPercent(e);
  }

  firstCapitalizeString(text: string) {
    return firstCapitalize(text);
  }

  onSubmitLeavePage() {
    this.$emit("update:leavePageConfirmation", false);
    this.$emit("leave-page");
  }

  onCloseLeavePage() {
    this.$emit("update:leavePageConfirmation", false);
    this.$emit("cancel-leave-page");
  }

  formatDate(date: string): string {
    return date ? formatDate(date) : "";
  }
}
