
import { Options, Vue } from "vue-class-component";

@Options({
  emits: ["update:isExpand", "update:selectedItem", "select"],
  props: {
    targetRef: {
      required: true
    },
    scrollPercent: {
      default: 0,
      type: Number
    }
  }
})
export default class CustomSelect extends Vue {
  openSelect = false;
  props: any = this.$props;

  doScroll() {
    if (this.props.scrollPercent === 100) {
      this.scrollTop();
    } else {
      this.scrollBottom();
    }
  }

  scrollTop() {
    this.props.targetRef.scroll({
      top: 0,
      behavior: "smooth"
    });
  }

  scrollBottom() {
    this.props.targetRef.scroll({
      top:
        this.props.targetRef.scrollHeight - this.props.targetRef.offsetHeight,
      behavior: "smooth"
    });
  }
}
